export function mapPlatformTypeColumns(type: string, platform: string) {
    let selectedColumns: string[] = [];

    if (type === 'Incident') {
        switch (platform) {
            case 'Novade':
                selectedColumns = [
                    'Event type',
                    'Date',
                    'Description of the Event',
                    'Status',
                    'Worksite/Compound',
                    'Name of person reporting',
                ];
                break;
            default:
                selectedColumns = [
                    'Event Type',
                    'Event Date Time',
                    'Event Description',
                    'Immediate Remedial Actions',
                    'Specific Location',
                    'Person Reporting',
                ];
                break;
        }
    } else if (type === 'Action') {
        selectedColumns = [
            'Project Depot',
            'Assignee Name',
            'Action Type',
            'Action Required',
            'Action Taken',
            'Status',
        ];
    }

    return selectedColumns;
}
