import {
    BrandVariants,
    GriffelStyle,
    Theme,
    createDarkTheme,
    createLightTheme,
    makeStyles,
    themeToTokensObject,
    tokens
} from '@fluentui/react-components';

// LIGHT PURPLE
// export const assuraTheme_BrandRamp: BrandVariants = {
//   10: "#020206",
//   20: "#121628",
//   30: "#192347",
//   40: "#1C2E62",
//   50: "#1E397E",
//   60: "#2D468F",
//   70: "#405297",
//   80: "#5260A0",
//   90: "#636DA9",
//   100: "#737BB2",
//   110: "#838ABB",
//   120: "#9398C4",
//   130: "#A3A7CD",
//   140: "#B3B6D6",
//   150: "#C4C5DF",
//   160: "#D4D5E8"
// };

// PURPLE
export const assuraTheme_BrandRamp: BrandVariants = { 
  10: "#030204",
  20: "#19151C",
  30: "#28222F",
  40: "#362C3F",
  50: "#443750",
  60: "#524262",
  70: "#614E74",
  80: "#705A87",
  90: "#7F669A",
  100: "#8F72AE",
  110: "#9D81B9",
  120: "#AA91C2",
  130: "#B6A1CB",
  140: "#C3B1D5",
  150: "#D0C1DE",
  160: "#DDD2E7"
};

// TEAL
// export const assuraTheme_BrandRamp: BrandVariants = { 
//   10: "#020304",
//   20: "#121A1B",
//   30: "#1B2B2E",
//   40: "#21383C",
//   50: "#27454B",
//   60: "#2D535A",
//   70: "#336169",
//   80: "#396F79",
//   90: "#447E88",
//   100: "#598B94",
//   110: "#6D98A1",
//   120: "#81A6AD",
//   130: "#94B4BA",
//   140: "#A8C1C7",
//   150: "#BCD0D3",
//   160: "#D0DEE1"
// };


// MUSTARD
// export const assuraTheme_BrandRamp: BrandVariants = { 
//   10: "#050301",
//   20: "#201609",
//   30: "#35230F",
//   40: "#452D11",
//   50: "#563812",
//   60: "#684313",
//   70: "#7A4E14",
//   80: "#8D5914",
//   90: "#A06513",
//   100: "#B47112",
//   110: "#C87D0F",
//   120: "#D78C28",
//   130: "#E19D4C",
//   140: "#E9AF6D",
//   150: "#F1C18E",
//   160: "#F7D3AE"
// };


// MID BLUE
// export const assuraTheme_BrandRamp: BrandVariants = { 
//   10: "#020305",
//   20: "#101822",
//   30: "#16273B",
//   40: "#18344F",
//   50: "#1A4065",
//   60: "#1A4E7B",
//   70: "#175B92",
//   80: "#1169AA",
//   90: "#2277BD",
//   100: "#4685C4",
//   110: "#6092CB",
//   120: "#78A0D2",
//   130: "#8EAED9",
//   140: "#A3BCE0",
//   150: "#B7CBE7",
//   160: "#CCD9EE"
// };

// GREEN
// export const assuraTheme_BrandRamp: BrandVariants = { 
//   10: "#030402",
//   20: "#161A15",
//   30: "#232C21",
//   40: "#2C382A",
//   50: "#364533",
//   60: "#40533C",
//   70: "#4A6146",
//   80: "#556F4F",
//   90: "#617D5B",
//   100: "#718B6C",
//   110: "#82987C",
//   120: "#92A68E",
//   130: "#A3B49F",
//   140: "#B4C2B1",
//   150: "#C6D0C3",
//   160: "#D8DFD6"
// };

// RED
// export const assuraTheme_BrandRamp: BrandVariants = { 
//   10: "#060201",
//   20: "#271010",
//   30: "#43151A",
//   40: "#5A1821",
//   50: "#731A28",
//   60: "#8C1A2F",
//   70: "#A61837",
//   80: "#BC2241",
//   90: "#C53F51",
//   100: "#CE5661",
//   110: "#D66B72",
//   120: "#DE7F83",
//   130: "#E59395",
//   140: "#EBA6A7",
//   150: "#F1BABA",
//   160: "#F6CDCD"
// };


export const theme1_LightTheme: Theme & { colorMeBackground: string } = {
    ...createLightTheme(assuraTheme_BrandRamp),
    colorMeBackground: '#fa450d',
};

export const theme1_DarkTheme: Theme & { colorMeBackground: string } = {
    ...createDarkTheme(assuraTheme_BrandRamp),
    colorMeBackground: '#fa450d',
};

export const candcLightTheme: Theme & { colorMeBackground: string } = {
    ...createLightTheme(assuraTheme_BrandRamp),
    colorMeBackground: '#fa450d',
};

export const candcDarkTheme: Theme & { colorMeBackground: string } = {
    ...createDarkTheme(assuraTheme_BrandRamp),
    colorMeBackground: '#fa450d',
};

export const customTokens = themeToTokensObject(candcLightTheme);

export const Breakpoints = {
    small: (style: GriffelStyle): Record<string, GriffelStyle> => {
        return { '@media (max-width: 744px)': style };
    },
};

export const ScrollBarStyles: GriffelStyle = {
    overflowY: 'scroll',
    '&:hover': {
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: tokens.colorScrollbarOverlay,
            visibility: 'visible',
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: tokens.colorNeutralBackground1,
            WebkitBoxShadow: 'inset 0 0 5px rgba(0, 0, 0, 0.1)',
            visibility: 'visible',
        },
    },
};

export const SharedStyles: Record<string, GriffelStyle> = {
    scroll: {
        height: '100%',
        ...ScrollBarStyles,
    },
};

export const useDialogClasses = makeStyles({
    root: {
        height: '515px',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '10px',
    },
    scopes: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '5px',
        paddingLeft: '20px',
    },
    error: {
        color: '#d13438',
    },
    section: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        rowGap: '10px',
    },
    footer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        minWidth: '175px',
    },
});
